import { CommonResponse, get, post } from './common.ts'

/**
 * board/login
 */
export const login = async (code: string): Promise<CommonResponse<null>> => {
  return await post('board/login', { code })
}

interface GetRequestCodeResonse {
  id: string
  code: string
}

export const getRequestCode = async (): Promise<CommonResponse<GetRequestCodeResonse>> => {
  return await get('board/requestCode')
}

interface PostCheckCodeRequest {
  id: string
}

export const postCheckCode = async (body: PostCheckCodeRequest): Promise<CommonResponse<null>> => {
  return await post('board/checkCode', body)
}
