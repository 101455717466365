import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getRequestCode, postCheckCode } from 'apis/board.ts'

export default function Login() {
  const navigate = useNavigate()
  const [status, setStatus] = useState(1)
  const [code, setCode] = useState('')

  let id
  const interval = useRef(null)

  useEffect(() => {
    return () => {
      clearInterval(interval.current)
      interval.current = null
    }
  }, [])

  const getRequestCodeApi = async () => {
    const getRequestCodeResponse = await getRequestCode()

    if (getRequestCodeResponse.error) {
      console.log(getRequestCodeResponse.message)
      return
    }

    id = getRequestCodeResponse.data.id
    setCode(getRequestCodeResponse.data.code)
    setStatus(2)

    clearInterval(interval.current)
    interval.current = null
    interval.current = setInterval(postCheckCodeApi, 1000)
  }

  const postCheckCodeApi = async () => {
    console.log('check')
    const postCheckCodeResponse = await postCheckCode({ id: id })

    if (postCheckCodeResponse.error) {
      return
    }

    clearInterval(interval.current)
    interval.current = null
    setStatus(3)
    navigate('/selectClassroom')
  }

  return (
    <div
      className='relative w-full h-screen bg-no-repeat bg-cover bg-dark'
      // style={{ backgroundImage: 'url(' + require('assets/images/background.png') + ')' }}
    >
      <div className='absolute flex flex-col transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
        <div className='flex flex-col items-center gap-64'>
          <div className='flex flex-col items-center justify-center'>
            <img
              src={require('assets/logos/logo_login.png')}
              alt=''
              className='w-[200px] h-[220px]'
            />
            <p className='text-[100px] text-blue-200 font-bold leading-[86.4px] tracking-[-1.56px]'>
              Edu LMS
            </p>
          </div>
          {status === 1 && (
            <div
              className='w-[802px] px-[160px] py-[32px] bg-[#06F] rounded-20 text-center select-none cursor-pointer'
              onClick={getRequestCodeApi}
            >
              <p className='text-white text-[36px] leading-[30px] tracking-[-2.5%] font-semibold'>
                로그인
              </p>
            </div>
          )}
          {status === 2 && (
            <div className='button-login' onClick={getRequestCodeApi}>
              <div className='flex gap-16'>
                <p className='text-text-light-assistive text-[36px] leading-[30px] tracking-[-2.5%] font-medium '>
                  로그인 코드
                </p>
                <p className='text-white text-[32px] leading-[30px] tracking-[-2.5%] font-medium'>
                  {code}
                </p>
              </div>
            </div>
          )}
          {status === 3 && (
            <Link to={'/selectClassroom'}>
              <div className='button-login'>
                <div className='flex gap-16'>
                  <p className='text-text-light-assistive text-[36px] leading-[30px] tracking-[-2.5%] font-medium '>
                    로그인 중입니다
                  </p>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
