import { CommonResponse, post } from './common.ts'
import { ATTENDANCE_STATUS, Timetable } from './enum.ts'

/**
 * lecture/list
 */
interface LectureListResponse {
  lectures: {
    id: string
    name: string
    timetable: Timetable
    studentCount: number
  }[]
}
export const list = async (): Promise<CommonResponse<LectureListResponse>> => {
  return await post('lecture/list', {})
}

/**
 * lecture/create
 */
interface LectureCreateRequest {
  name: string
  timetable: Timetable
}
export const create = async (body: LectureCreateRequest): Promise<CommonResponse<null>> => {
  return await post('lecture/create', body)
}

/**
 * lecture/delete
 */
interface LectureDeleteRequest {
  lectureId: string
}
export const del = async (body: LectureDeleteRequest): Promise<CommonResponse<null>> => {
  return await post('lecture/delete', body)
}

/**
 * lecture/student/list
 */
interface LectureStudentListRequest {
  lectureId: string
}
interface LectureStudentListResponse {
  students: {
    id: string
    name: string
    grade: number
    classNo: number
    phoneNumber: string
  }[]
}
export const studentList = async (
  body: LectureStudentListRequest
): Promise<CommonResponse<LectureStudentListResponse>> => {
  return await post('lecture/student/list', body)
}

/**
 * lecture/student/create
 */
interface LectureStudentCreateRequest {
  lectureId: string
  name: string
  grade: number
  classNo: number
  phoneNumber: string
}
export const studentCreate = async (
  body: LectureStudentCreateRequest
): Promise<CommonResponse<null>> => {
  return await post('lecture/student/create', body)
}

/**
 * lecture/student/createMany
 */
interface LectureStudentCreateManyRequest {
  lectureId: string
  students: {
    name: string
    grade: number
    classNo: number
    phoneNumber: string
  }[]
}
export const studentCreateMany = async (
  body: LectureStudentCreateManyRequest
): Promise<CommonResponse<null>> => {
  return await post('lecture/student/createMany', body)
}

/**
 * lecture/student/edit
 */
interface LectureStudentEditRequest {
  lectureId: string
  studentId: string
  name: string
  grade: number
  classNo: number
  phoneNumber: string
}
export const studentEdit = async (
  body: LectureStudentEditRequest
): Promise<CommonResponse<null>> => {
  return await post('lecture/student/edit', body)
}

/**
 * lecture/student/delete
 */
interface LectureStudentDeleteRequest {
  lectureId: string
  studentId: string
}
export const studentDelete = async (
  body: LectureStudentDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('lecture/student/delete', body)
}

/**
 * lecture/student/deleteMany
 */
interface LectureStudentDeleteManyRequest {
  lectureId: string
  studentIds: string[]
}
export const studentDeleteMany = async (
  body: LectureStudentDeleteManyRequest
): Promise<CommonResponse<null>> => {
  return await post('lecture/student/deleteMany', body)
}

/**
 * lecture/student/attendance/list
 */
interface LectureStudentAttendanceListRequest {
  lectureId: string
  date: string // 2024-01-01
}
interface LectureStudentAttendanceListResponse {
  students: {
    id: string
    name: string
    grade: number
    classNo: number
    phoneNumber: string
    attendance: ATTENDANCE_STATUS
  }[]
}
export const studentAttendanceList = async (
  body: LectureStudentAttendanceListRequest
): Promise<CommonResponse<LectureStudentAttendanceListResponse>> => {
  return await post('lecture/student/attendance/list', body)
}

/**
 * lecture/student/attendance/save
 */
interface LectureStudentAttendanceSaveRequest {
  lectureId: string
  date: string // 2024-01-01
  students: {
    id: string
    attendance: ATTENDANCE_STATUS
  }[]
}
export const studentAttendanceSave = async (
  body: LectureStudentAttendanceSaveRequest
): Promise<CommonResponse<null>> => {
  return await post('lecture/student/attendance/save', body)
}
