import React, { useState, useEffect, useRef } from 'react'
import { components } from 'components/customMenu'
import { Tldraw } from 'tldraw'
// import Viewer from 'components/pdfViewer'
import BrowseFileModal from 'components/BrowseFileModal'
import { useSync } from '@tldraw/sync'

import Time from 'assets/images/electricboard-time.svg'
import File from 'assets/images/electricboard-file.svg'
import Picture from 'assets/images/electricboard-picture.svg'
import Record from 'assets/images/electricboard-record.svg'
import Back from 'assets/images/electricboard-back-white.svg'

import Chat from 'assets/images/electricboard-chat.svg'
import Mike from 'assets/images/electricboard-mike.svg'

import Camera from 'assets/images/electricboard-camera.svg'
import People from 'assets/images/electricboard-people.svg'

/* import Back from 'assets/images/electricboard-back.svg'

import Return from 'assets/images/electricboard-reutrn.svg'
import Arrow from 'assets/images/electricboard-arrow-selector.svg'
import Edit from 'assets/images/electricboard-edit.svg'
import Highliter from 'assets/images/electirboard-highliter.svg'
import Eraser from 'assets/images/electricboard-eraser.svg'

import Round1 from 'assets/images/electricboard-round1.svg'
import Round2 from 'assets/images/electricboard-round2.svg'
import Round3 from 'assets/images/electricboard-round3.svg'
import Round4 from 'assets/images/electricboard-round4.svg'
import Round5 from 'assets/images/electricboard-round5.svg'
import Round6 from 'assets/images/electricboard-round6.svg'
import Round7 from 'assets/images/electricboard-round7.svg' */

import { minutesToHourAndMinutes2 } from 'apis/common.ts'
import { page as pageApi } from 'apis/content.ts'
import { TLDRAW_WORKER_URL } from 'constants/settings.ts'
import { multiplayerAssetStore } from 'components/multiplayerAssetStore.tsx'
import { getBookmarkPreview } from 'components/getBookmarkPreview.tsx'
import { useNavigate, useParams } from 'react-router-dom'

/* function InsideOfContext({ handleToolChange }) {
  const editor = useEditor()
  useEffect(() => {
    if (editor) {
      handleToolChange(editor)
    }
  }, [editor, handleToolChange])
  handleToolChange(editor)

  return null
} */

export default function Classroom() {
  const [browseFileModalOpen, setBrowseFileModalOpen] = useState(false)
  const [minutes, setMinutes] = useState(0)
  const { id } = useParams()
  const editorRef = useRef(null)
  const router = useNavigate();

  useEffect(() => {
    let interval = setInterval(() => {
      setMinutes(minutes + 1)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })

  const handleEvent = (name, data) => {
    console.log(name)
    console.log(data)
  }

  const getDimensions = async (url) => {
    const img = new Image()
    img.src = url
    await img.decode()
    return img
  }

  const nowHeight = useRef(0)

  const addPage = async (contentId, totalPage) => {
    const editor = editorRef.current
    for await (const page of [...Array(totalPage).keys()]) {
      // get image size from url
      const image = await getDimensions(pageApi(contentId, page + 1))
      const { width, height } = image

      editor.createAssets([
        {
          id: `asset:${contentId}-${page}`,
          typeName: 'asset',
          type: 'image',
          meta: {},
          props: {
            w: width,
            h: height,
            mimeType: 'image/png',
            src: image.src,
            name: 'page',
            isAnimated: false,
          },
        },
      ])
      editor.createShapes([
        {
          id: `shape:${contentId}-${page}`,
          type: 'image',
          x: window.innerWidth / 2 - width / 2,
          y: nowHeight.current,
          isLocked: true,
          props: {
            assetId: `asset:${contentId}-${page}`,
            w: width / 2,
            h: height / 2,
          },
        },
      ])
      nowHeight.current += height / 2 + 20
    }
  }

  const roomId = id
  const store = useSync({
    uri: TLDRAW_WORKER_URL + '/connect/' + roomId,
    assets: multiplayerAssetStore,
  })

  return (
    <div className='h-screen'>
      {browseFileModalOpen && (
        <BrowseFileModal
          addPage={addPage}
          browseFileModalOpen={browseFileModalOpen}
          setBrowseFileModalOpen={setBrowseFileModalOpen}
        ></BrowseFileModal>
      )}
      <div className='shrink-0 h-[150px] flex justify-center items-center'>
        <div className='flex items-center justify-center gap-[10px]'>
          <div className='flex justify-center items-center overflow-hidden w-[180px] gap-8 px-20 py-6 rounded-[100px] bg-[#D4E1FF] mr-12 text-body2-long-medium text-primary select-none cursor-pointer'>
            <img src={Time} alt='' className='w-[48px] h-[48px]' />
            <p className='text-[24px]'>{minutesToHourAndMinutes2(minutes)}</p>
          </div>
          <div
            className='flex justify-center items-center gap-8 px-20 py-6 border-[2px] border-border-light rounded-[100px] bg-white mr-6 text-body2-long-medium text-text-light select-none cursor-pointer'
            onClick={() => setBrowseFileModalOpen(true)}
          >
            <img src={File} alt='' className='w-[48px] h-[48px]' />
            <p className='text-[24px]'>파일 불러오기</p>
          </div>
          <div className='flex justify-center items-center gap-8 px-20 py-6 border-[2px] border-border-light rounded-[100px] bg-white mr-6 text-body2-long-medium text-text-light select-none cursor-pointer'>
            <img src={Picture} alt='' className='w-[48px] h-[48px]' />
            <p className='text-[24px]'>이미지/동영상 삽입하기</p>
          </div>
          <div className='flex justify-center items-center gap-8 px-20 py-6 border-[2px] border-[#FCD4DE] rounded-[100px] bg-[#FF5959] mr-6 text-body2-long-medium text-white select-none cursor-pointer'>
            <img src={Record} alt='' className='w-[48px] h-[48px]' />
            <p className='text-[24px]'>녹화하기</p>
          </div>
          <div className='flex justify-center items-center gap-8 px-20 py-6 border-[2px] border-border-light rounded-[100px] bg-white mr-6 text-body2-long-medium text-text-light select-none cursor-pointer'
            onClick={() => {
              router("/selectClassroom", {
                replace: true
              })
            }}>
            <img src={Back} alt='' className='w-[48px] h-[48px] bg-white' />
            <p className='text-[24px]'>나가기</p>
          </div>
          {/* <div className='flex justify-center items-center px-20 py-6 border border-border-light rounded-[100px] bg-white mr-12'>
            <div className='flex justify-center items-center gap-4 mr-[11px]'>
              <img src={Back} alt='' className='w-[48px] h-[48px]' />
              <img src={Return} alt='' className='w-[48px] h-[48px]' />
            </div>
            <div className='border-[1.5px] h-[40px]'></div>
            <div className='flex justify-center items-center gap-8 mx-[11px]'>
              <img src={Arrow} alt='' className='w-[48px] h-[48px]' />
              <img src={Edit} alt='' className='w-[48px] h-[48px]' />
              <img src={Highliter} alt='' className='w-[48px] h-[48px]' />
              <img src={Eraser} alt='' className='w-[48px] h-[48px]' />
            </div>
            <div className='border-[1.5px] h-[40px]'></div>
            <div className='flex justify-center items-center gap-8 mx-[11px]'>
              <img src={Round1} alt='' className='w-[48px] h-[48px]' />
              <img src={Round2} alt='' className='w-[48px] h-[48px]' />
              <img src={Round3} alt='' className='w-[48px] h-[48px]' />
              <img src={Round4} alt='' className='w-[48px] h-[48px]' />
            </div>
            <div className='border-[1.5px] h-[40px]'></div>
            <div className='flex justify-center items-center gap-8 mx-[11px]'>
              <img src={Round5} alt='' className='w-[48px] h-[48px]' />
              <img src={Round6} alt='' className='w-[48px] h-[48px]' />
              <img src={Round7} alt='' className='w-[48px] h-[48px]' />
            </div>
          </div> */}
          <div className='flex justify-between gap-8 drag-none'>
            <img src={Chat} alt='' className='cursor-pointer w-[52px] h-[52px]' />
            <img src={Mike} alt='' className='cursor-pointer w-[52px] h-[52px]' />
            <img src={Camera} alt='' className='cursor-pointer w-[52px] h-[52px]' />
            <img src={People} alt='' className='cursor-pointer w-[52px] h-[52px]' />
          </div>
        </div>
      </div>
      <div className='flex w-full h-[calc(100%-150px)]'>
        {/* <div className='flex items-center justify-center w-[50%]'>
          <Viewer pdfWidth={800} />
        </div> */}
        <div className='z-0 w-[100%]'>
          <Tldraw
            onUiEvent={handleEvent}
            store={store}
            onMount={(editor) => {
              editorRef.current = editor
              editor.registerExternalAssetHandler('url', getBookmarkPreview)
            }}
            components={components}
          >
            {/* <InsideOfContext handleToolChange={setEditorInstance} /> */}
          </Tldraw>
        </div>
      </div>
    </div>
  )
}
