import { RecoilRoot } from 'recoil'
import RootRouter from 'RootRouter'

function App() {
  return (
    <div className='App'>
      <RecoilRoot>
        <RootRouter />
      </RecoilRoot>
    </div>
  )
}

export default App
