import {
  DefaultStylePanel,
  DefaultStylePanelContent,
  DefaultToolbar,
  DefaultToolbarContent,
  useRelevantStyles,
  DefaultMenuPanel,
} from 'tldraw'
import 'tldraw/tldraw.css'

//[10]
function CustomStylePanel(props) {
  const styles = useRelevantStyles()

  return (
    <div className='scale-[2] translate-x-[-50%] translate-y-[50%]'>
      <DefaultStylePanel {...props}>
        <DefaultStylePanelContent styles={styles} />
      </DefaultStylePanel>
    </div>
  )
}
//[11]
function CustomToolbar() {
  return (
    <div className='scale-[2] translate-y-[-50%]'>
      <DefaultToolbar>
        <DefaultToolbarContent />
      </DefaultToolbar>
    </div>
  )
}
function CustomMenuPanel() {
  return (
    <div className='scale-[2] translate-x-1/2 translate-y-1/2'>
      <DefaultMenuPanel></DefaultMenuPanel>
    </div>
  )
}
export const components = {
  StylePanel: CustomStylePanel,
  Toolbar: CustomToolbar,
  MenuPanel: CustomMenuPanel,
  KeyboardShortcutsDialog: null,
  QuickActions: null,
  HelperButtons: null,
  DebugPanel: null,
  Minimap: null,
  DebugMenu: null,
  SharePanel: null,
  TopPanel: null,
  CursorChatBubble: null,
}
