import React, { useEffect, useState } from 'react'
import IconClassroom1 from 'assets/icons/icon-classroom1.svg'
// import IconClassroom2 from 'assets/icons/icon-classroom2.svg'
import { Link } from 'react-router-dom'
import { list as listApi } from 'apis/lecture.ts'

export default function SelectClassroom() {
  const [selectedId, setSelectedId] = useState(null)
  const [list, setList] = useState([])

  const getLectureList = async () => {
    const res = await listApi()
    console.log(res)
    if (!res.error) {
      setList(res.data.lectures)
    }
  }
  useEffect(() => {
    getLectureList()
  }, [])

  return (
    <div
      className='relative w-full h-screen bg-no-repeat bg-cover bg-dark'
      // style={{ backgroundImage: 'url(' + require('assets/images/background-dark.png') + ')' }}
    >
      <div className='absolute flex flex-col transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
        <div className='flex w-[2500px] h-[1500px]'>
          {/* classroom 목록 */}
          <div className='basis-8/12 div-classroomList_left '>
            {list.map((classroom, index) => (
              <div
                key={index}
                className={`flex flex-col gap-[20px] div-classroom hover:outline-[3px] hover:outline-primary-hover ${
                  selectedId === classroom.id
                    ? 'outline !outline-primary outline-[3px]'
                    : 'outline outline-0'
                }`}
                onClick={() => setSelectedId(classroom.id)}
              >
                <div className='flex items-center gap-[32px]'>
                  <img src={IconClassroom1} alt='' className='w-[60px] h-[60px]' />
                  <p className='text-white text-[52px] font-medium'>{classroom.name}</p>
                </div>
                <div className='flex flex-col gap-8'>
                  <p className='text-white text-[36px] font-medium'>{classroom.teacher} 선생님</p>
                  <p className='text-white text-[36px] font-medium'>
                    {classroom.timetable.items[0].dayOfWeek}{' '}
                    {classroom.timetable.items[0].startTime}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* 수업방 선택하기 */}
          <div className='basis-4/12 div-classroomList_right'>
            <div className='flex flex-col justify-between h-full'>
              <div className='flex flex-col gap-12'>
                <p className='text-white text-[60px] font-bold'>수업방 선택하기</p>
                <p className='text-[32px] leading-[32px] trakcing-[-2.5%] font-medium text-text-light-assistive'>
                  진행할 수업을 선택해주세요
                </p>
              </div>
              <div className='flex flex-col gap-32'>
                <div
                  onClick={() => getLectureList()}
                  className={`px-[160px] py-[32px] rounded-20 text-center select-none cursor-pointer ${'bg-[#66A3FF] '}`}
                >
                  <p className='text-white text-[36px] leading-[30px] tracking-[-2.5%] font-semibold'>
                    새로고침
                  </p>
                </div>
                <Link to={'/classroom/' + selectedId}>
                  <div
                    className={`px-[160px] py-[32px] rounded-20 text-center select-none cursor-pointer ${
                      selectedId === null ? 'bg-gray-600' : 'bg-[#06F] '
                    }`}
                  >
                    <p className='text-white text-[36px] leading-[30px] tracking-[-2.5%] font-semibold'>
                      입장하기
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
