import React, { useRef, useState, useEffect } from 'react'

import Close from 'assets/icons/close.svg'
import PDF from 'assets/icons/content-pdf.svg'
import Public from 'assets/icons/content-public.svg'
import Private from 'assets/icons/content-private.svg'

// import { useSetRecoilState } from 'recoil'
// import pdfFileState from 'states/pdfFileState.ts'

import { list, thumbnail } from 'apis/content.ts'

export default function BrowseFileModal({ addPage, browseFileModalOpen, setBrowseFileModalOpen }) {
  const modalBackground = useRef()
  const [contentList, setContentList] = useState([
    {
      id: PDF,
      title: '기업자원통합관리(ERP)분석 및 보고서 작성.pdf',
      extension: 'pdf',
      img: require('assets/images/content-sumnail.png'),
      type: 'public',
      division: '기업자원통합관리',
    },
  ])
  const getList = async () => {
    const listRes = await list()
    if (listRes.error) {
      console.log(listRes.message)
    } else {
      console.log(listRes.data)
      setContentList([])
      listRes.data.contents.forEach((content) => {
        let tempItem = {}
        tempItem.id = content.id
        tempItem.title = content.name
        tempItem.extension = 'pdf'
        tempItem.totalPage = content.totalPage
        tempItem.type = content.isPrivate ? 'private' : 'public'
        tempItem.division = Math.round(content.fileSize / 1024)
        tempItem.img = thumbnail(content.id)
        setContentList((prev) => [...prev, tempItem])
      })
    }
  }
  useEffect(() => {
    getList()
  }, [])

  // const setPdfFile = useSetRecoilState(pdfFileState)

  return (
    <div className='fixed top-0 left-0 z-[999] w-screen h-screen cursor-default'>
      {/* 모달 배경 */}
      <div
        className='absolute w-full h-full bg-black/70 '
        ref={modalBackground}
        onClick={(e) => {
          setBrowseFileModalOpen((prev) => !prev)
        }}
      />
      {/* 모달 */}
      <div className='absolute scale-[2] w-[1200px] max-h-[800px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-16 '>
        <div className='flex flex-col p-20 gap-36'>
          <div className='flex flex-col items-center'>
            <div className='relative flex justify-center w-full select-none'>
              <p className='text-h1-semibold text-text-light'>파일 불러오기</p>
              <div className='absolute right-0'>
                <button onClick={() => setBrowseFileModalOpen((prev) => !prev)}>
                  <img src={Close} alt='' className='w-36 h-36' />
                </button>
              </div>
            </div>

            <p className='select-none text-body1-medium text-text-light-sub'>
              불러올 파일을 선택해주세요
            </p>
          </div>

          <div className='grid grid-cols-3 gap-x-32 gap-y-24'>
            {contentList.map((content, index) => (
              <div
                className='bg-background-light-alternative px-20 py-20 rounded-8 border-[0.484px] border-border-light cursor-pointer'
                onClick={() => {
                  addPage(content.id, content.totalPage)
                  setBrowseFileModalOpen((prev) => !prev)
                }}
              >
                <div className='flex flex-col gap-12'>
                  {/* 콘텐츠 제목 */}
                  <div className='flex items-center gap-4 truncate'>
                    {content.extension === 'pdf' && <img src={PDF} alt='' />}
                    <p className='truncate text-body1-long-semibold text-text-light'>
                      {content.title}
                    </p>
                  </div>

                  {/* 콘텐츠 이미지 */}
                  <div className='h-[100px] overflow-hidden rounded-4 border border-border-light'>
                    <img src={content.img} alt='' className='w-full' />
                  </div>

                  {/* 콘텐츠 설명 */}
                  <div className='flex items-center justify-between gap-6'>
                    {content.type === 'public' && <img src={Public} alt='' />}
                    {content.type === 'private' && <img src={Private} alt=''></img>}
                    <div className='text-body1-long-medium text-text-light-sub'>
                      <p>{content.division} MB</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*
          <div className='flex items-center justify-center'>
            <div
              className='basis-1/2 flex justify-center items-center py-[11px] cursor-pointer'
              onClick={() => setBrowseFileModalOpen((prev) => !prev)}
            >
              <p className='text-h2-semibold text-text-light'>취소</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
