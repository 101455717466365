import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import Classroom from 'views/Classroom'
import Login from 'views/Login'
import SelectClassroom from 'views/SelectClassroom'

import { useRecoilState } from 'recoil'
import loginState from 'states/loginState.ts'
import * as user from 'apis/user.ts'
import { useEffect } from 'react'

function RequireAuth({ children, redirectTo }) {
  const [login, setLogin] = useRecoilState(loginState)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await user.profile()
      console.log(profileRes)
      if (profileRes.error) {
        setLogin({ isLoggedIn: false, id: null, organizationId: null })
      } else {
        setLogin({
          isLoggedIn: true,
          id: profileRes.data.id,
          organizationId: profileRes.data.organizationId,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === true) {
    return children
  } else if (login.isLoggedIn === false) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

function NoRequireAuth({ children, redirectTo }) {
  const [login, setLogin] = useRecoilState(loginState)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await user.profile()
      if (profileRes.error) {
        setLogin({ isLoggedIn: false, id: null, organizationId: null })
      } else {
        setLogin({
          isLoggedIn: true,
          id: profileRes.data.id,
          organizationId: profileRes.data.organizationId,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === false) {
    return children
  } else if (login.isLoggedIn === true) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

export default function RootRouter() {
  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            <NoRequireAuth redirectTo={'/selectClassroom'}>
              <Login />
            </NoRequireAuth>
          }
        />
        <Route
          path='/selectClassroom'
          element={
            <RequireAuth redirectTo={'/'}>
              <SelectClassroom />
            </RequireAuth>
          }
        />
        <Route
          path='/classroom/:id'
          element={
            <RequireAuth redirectTo={'/'}>
              <Classroom />
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  )
}
